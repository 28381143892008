import axios from 'axios'

const db = axios.create({
  baseURL: process.env.API_HOST,
})

const initEmbedly = () => {
  if (typeof window !== 'undefined') {
    const initEmbedly = (w, d) => {
      const id = 'embedly-platform'
      const n = 'script'

      if (!d.getElementById(id)) {
        w.embedly =
          w.embedly ||
          (() => {
            ;(w.embedly.q = w.embedly.q || []).push(arguments)
          })

        let e = d.createElement(n)
        e.id = id
        e.async = true
        e.src =
          (document.location.protocol === 'https:' ? 'https' : 'http') +
          '://cdn.embedly.com/widgets/platform.js'

        const s = d.getElementsByTagName(n)[0]
        s.parentNode.insertBefore(e, s)
      }
    }

    initEmbedly(window, document)
  }
}

export { db, initEmbedly }
